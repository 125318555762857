import Vue from 'vue'
import {
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Popover,
  Loading,
  Message,
  Drawer,
  Switch,
  Slider
} from 'element-ui'

Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Popover)
Vue.use(Drawer)
Vue.use(Switch)
Vue.use(Slider)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message