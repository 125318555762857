import AES from "./encryption/aesTool/aesAll";
let BASE_64 = require('./encryption/base64/base64Util');

// 默认的 KEY 与 iv 如果没有给
const KEY = AES.CryptoJS.enc.Utf8.parse("1234567890123456");
const IV = AES.CryptoJS.enc.Utf8.parse('1234567890123456');

/**
 * AES加密 ：字符串 key iv  返回base64
 */
export default {
    encryptData: encryptData,
    decryptData: decryptData
}

function encrypt(word, keyStr, ivStr) {
    let key = KEY;
    let iv = IV;

    if (keyStr) {
        key = AES.CryptoJS.enc.Utf8.parse(keyStr);
        iv = AES.CryptoJS.enc.Utf8.parse(ivStr);
    }

    let srcs = AES.CryptoJS.enc.Utf8.parse(word);
    let encrypted = AES.CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: AES.CryptoJS.mode.CBC,
        padding: AES.CryptoJS.pad.ZeroPadding
    });
    return AES.CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

/**
 * AES 解密 ：字符串 key iv  返回base64
 */
function decrypt(word, keyStr, ivStr) {
    let key = KEY;
    let iv = IV;

    if (keyStr) {
        key = AES.CryptoJS.enc.Utf8.parse(keyStr);
        iv = AES.CryptoJS.enc.Utf8.parse(ivStr);
    }

    let base64 = AES.CryptoJS.enc.Base64.parse(word);
    let src = AES.CryptoJS.enc.Base64.stringify(base64);
    let decrypt = AES.CryptoJS.AES.decrypt(src, key, {
        iv: iv,
        mode: AES.CryptoJS.mode.CBC,
        padding: AES.CryptoJS.pad.ZeroPadding
    });
    let decryptedStr = decrypt.toString(AES.CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}

function encryptData(sourceContent) {
    sourceContent = BASE_64.encode(sourceContent);
    sourceContent = BASE_64.encode(sourceContent);
    sourceContent = encrypt(sourceContent);
    return sourceContent;
}

function decryptData(sourceContent) {
    sourceContent = decrypt(sourceContent);
    sourceContent = BASE_64.decode(sourceContent);
    sourceContent = BASE_64.decode(sourceContent);
    return sourceContent;
}