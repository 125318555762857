import store from '@/store'

export function checkIsMember(memberStatus) {
  let isMember = false
  let now = new Date().getTime()
  let { current_purchase_status } = memberStatus

  for (let payType in current_purchase_status) {
    let payTypeData = current_purchase_status[payType]
    for (let premiumType in payTypeData) {
      let premiumTypeData = payTypeData[premiumType]
      let { expired_date_ms } = premiumTypeData
      if (expired_date_ms && expired_date_ms > now) {
        isMember = true
        store.commit('set_accountType', premiumType)
        return isMember
      }
    }
  }

  return isMember
}

export function checkIsBigFans(bigFansStatus) {
  let isBigFans = false
  let { user_type } = bigFansStatus

  if (user_type === 1) isBigFans = true

  return isBigFans
}

export function checkIsHaveFreeTrial(memberStatus) {
  let isHaveFreeTrial = true
  let now = new Date().getTime()
  let { current_purchase_status } = memberStatus

  for (let payType in current_purchase_status) {
    let payTypeData = current_purchase_status[payType]
    for (let premiumType in payTypeData) {
      let premiumTypeData = payTypeData[premiumType]
      let { expired_date_ms } = premiumTypeData
      if (expired_date_ms && expired_date_ms < now) {
        isHaveFreeTrial = false
        return isHaveFreeTrial
      }
    }
  }

  return isHaveFreeTrial
}