import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '../store/index'

let Home = () => import('../views/Home.vue')
let Links = () => import('../views/Links.vue')
let ChooseLang = () => import('../views/ChooseLang.vue')
let Settings = () => import('../views/Settings.vue')
let Clips = () => import('../views/Clips.vue')
let ClipsLesson = () => import('../views/ClipsLesson.vue')
let Courses = () => import('../views/Courses.vue')
let CoursesLesson = () => import('../views/CoursesLesson.vue')
let CoursesDialogue = () => import('../views/CoursesDialogue.vue')
let CoursesSpeaking = () => import('../views/CoursesSpeaking.vue')
let SpeakingFollow = () => import('../components/courses/speaking/SpeakingFollow.vue')
let SpeakingRoleplay = () => import('../components/courses/speaking/SpeakingRoleplay.vue')
let GrammarTips = () => import('../views/GrammarTips.vue')
let Alphabet = () => import('../views/Alphabet.vue')
let Pricing = () => import('../views/Pricing.vue')
let PricingPlan = () => import('../components/price/PricingPlan.vue')
let PricingOrder = () => import('../components/price/PricingOrder.vue')
let PricingResult = () => import('../components/price/PricingResult.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/links',
    name: 'Links',
    component: Links
  },
  {
    path: '/clips',
    name: 'Clips',
    component: Clips,
    beforeEnter: (to, from, next) => {
      let lang = store.getters.userLanguage
      if (!lang || lang === 'en') {
        next({ name: 'Start' })
      } else {
        next()
      }
    }
  },
  {
    path: '/clips/lesson',
    name: 'ClipsLesson',
    component: ClipsLesson
  },
  {
    path: '/courses',
    name: 'Courses',
    component: Courses,
    beforeEnter: (to, from, next) => {
      let lang = store.getters.userLanguage
      if (!lang || lang === 'en') {
        next({ name: 'Start' })
      } else {
        next()
      }
    }
  },
  {
    path: '/courses/lesson',
    name: 'CoursesLesson',
    component: CoursesLesson
  },
  {
    path: '/courses/dialogue',
    name: 'CoursesDialogue',
    component: CoursesDialogue
  },
  {
    path: '/courses/speaking',
    component: CoursesSpeaking,
    children: [
      {
        path: 'follow',
        name: 'SpeakingFollow',
        component: SpeakingFollow
      },
      {
        path: 'roleplay',
        name: 'SpeakingRoleplay',
        component: SpeakingRoleplay
      }
    ]
  },
  {
    path: '/pricing',
    component: Pricing,
    redirect: { name: 'PricingPlan' },
    children: [
      {
        path: 'plan',
        name: 'PricingPlan',
        component: PricingPlan
      },
      {
        path: 'order/:type?',
        name: 'PricingOrder',
        component: PricingOrder,
        props: true
      },
      {
        path: 'result',
        name: 'PricingResult',
        component: PricingResult,
        props: true
      }
    ]
  },
  {
    path: '/grammarTips',
    name: 'GrammarTips',
    component: GrammarTips
  },
  {
    path: '/alphabet',
    name: 'Alphabet',
    component: Alphabet
  },
  {
    path: '/start',
    name: 'Start',
    component: ChooseLang
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from ,next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
