import { ref, set } from 'firebase/database'
import store from '../store/index'

const DefaultProgress = {
  lesson_exam: '',
  lesson_stars: '',
  main: '',
  main_tt: '',
  pronun: 1
}

export async function initTodayData() {
  let historyData = store.getters.historyData
  let todayDate = getTodayDate()

  if (historyData) {
    let records = historyData.split(';').filter(item => item)
    let todayRecord = records.find(record => record.split(':')[0] === todayDate)

    if (!todayRecord) {
      updateHistoryData(0, 0)
    }
  }
}

export async function updateHistoryData(learnDuration, earnXP) {
  let userInfo = store.getters.userInfo
  let historyData = store.getters.historyData
  let todayDate = getTodayDate()
  let updateTime = Date.parse(new Date()) / 1000
  
  if (historyData) {
    // 查看有没有今天的数据
    let records = historyData.split(';').filter(item => item)
    let todayRecord = records.find(record => record.split(':')[0] === todayDate)
    if (todayRecord) {
      let durationAndXP = todayRecord.split(':')[1]
      let duration = Number(durationAndXP.split('_')[0])
      let xp = Number(durationAndXP.split('_')[1])
      let newRecord = `${todayDate}:${duration + learnDuration}_${xp + earnXP}`
      historyData = historyData.replace(todayRecord, newRecord)
    } else {
      todayRecord = `${todayDate}:${learnDuration}_${earnXP};`
      historyData = todayRecord + historyData
    }
  } else {
    historyData = `${todayDate}:${learnDuration}_${earnXP};`
  }

  if (userInfo) {
    await set(ref(window.db_srs, `users_private/${userInfo.UID}/learning_history`), {
      last_update_time: updateTime,
      record: historyData
    })
  }
  store.commit('set_historyData', historyData)
}

export async function updateClipsProgressData(lessonID, medal, progress) {
  let langParam = `${store.getters.learnLanguage}pal` // enpal / espal / frpal...
  let userInfo = store.getters.userInfo
  let progressData = store.getters.progressData
  let clipsProgressData = progressData?.[langParam]?.m_movie
  let newLessonStar = lessonID + ':' + medal
  let newLessonProgress = lessonID + ':' + progress

  if (clipsProgressData) {
    let { lesson_stars, main_tt } = clipsProgressData
    
    if (lesson_stars === '') {
      lesson_stars = newLessonStar + ';'
    } else {
      let lessonStarsArr = lesson_stars.split(';').filter(item => item !== '')
      let lessonIDArr = lessonStarsArr.map(item => item.split(':')[0])
      if (lessonIDArr.includes(lessonID)) {
        for (let lessonStar of lessonStarsArr) {
          let id = lessonStar.split(':')[0]
          let starNum = lessonStar.split(':')[1]
          if ((lessonID === id) && (medal > starNum)) {
            lesson_stars = lesson_stars.replace(lessonStar, newLessonStar)
          }
        }
      } else {
        lesson_stars = lesson_stars + newLessonStar + ';'
      }
    }
    clipsProgressData.lesson_stars = lesson_stars
    
    if (main_tt === '') {
      main_tt = newLessonProgress + ';'
    } else {
      let lessonProgressArr = main_tt.split(';').filter(item => item !== '')
      let lessonIDArr = lessonProgressArr.map(item => item.split(':')[0])
      if (lessonIDArr.includes(lessonID)) {
        for (let lessonProgress of lessonProgressArr) {
          let id = lessonProgress.split(':')[0]
          let progressVal = lessonProgress.split(':')[1]
          let currentProgress = Number(progressVal.split('/')[0])
          let newProgress = Number(progress.split('/')[0])
          if ((lessonID === id) && (newProgress > currentProgress)) {
            main_tt = main_tt.replace(lessonProgress, newLessonProgress)
          }
        }
      } else {
        main_tt = main_tt + newLessonProgress + ';'
      }
    }
    clipsProgressData.main_tt = main_tt
  } else {
    clipsProgressData = JSON.parse(JSON.stringify(DefaultProgress))
    clipsProgressData.lesson_stars = newLessonStar + ';'
    clipsProgressData.main_tt = newLessonProgress + ';'
  }

  if (progressData) {
    if (progressData[langParam]) {
      progressData[langParam]['m_movie'] = clipsProgressData
    } else {
      progressData[langParam] = { m_movie: clipsProgressData }
    }
  } else {
    progressData = { [langParam]: { m_movie: clipsProgressData } }
  }

  if (userInfo) {
    await set(ref(window.db_core, `users_private/${userInfo.UID}/learning_progress/${langParam}/m_movie`), clipsProgressData)
  }
  store.commit('set_progressData', progressData)
}

export async function updateCoursesProgressData(lessonData, starNum) {
  let langParam = `${store.getters.learnLanguage}pal` // enpal / espal / frpal...
  let userInfo = store.getters.userInfo
  let unitsData = store.getters.coursesUnitsData
  let progressData = store.getters.progressData
  let coursesProgressData = progressData?.[langParam]?.m_course
  
  let type = lessonData.type
  let lessonID = lessonData.id
  let lessonIndex = lessonData.index
  let unitID = lessonData.unitID

  let currentUnit = unitsData.find(unit => unit.lsID === unitID)
  let currentUnitIndex = unitsData.findIndex(item => item.lsID === unitID) // 包含testout的index
  let unitIndex = currentUnit.lsIndex // 剔除testout后的index
  let nextUnit = unitsData[currentUnitIndex + 1]

  if (type === 'testout') {
    // 一. 生成进度数据
    let main_ttStr = ''
    for (let unit of unitsData) {
      if (unitID !== unit.lsID) {
        if (unit.ls[0].type !== 'testout') {
          main_ttStr += `${unit.lsID}:1;`
        }
      } else {
        main_ttStr += `${nextUnit.lsID}:1;`
        break
      }
    }
    let currentMain_ttArr =  main_ttStr.split(';').filter(item => item !== '')
    
    // 二. merge进度数据
    if (coursesProgressData) {
      let { main_tt } = coursesProgressData
      if (main_tt) {
        let main_ttArr = main_tt.split(';').filter(item => item !== '')
        let unitIDArr = main_ttArr.map(item => item.split(':')[0])
        for (let main_ttVal of currentMain_ttArr) {
          let unitID = main_ttVal.split(':')[0]
          if (!unitIDArr[unitID]) {
            main_tt += main_ttVal + ';'
          }
        }
        coursesProgressData.main_tt = main_tt
      } else {
        coursesProgressData.main_tt = main_ttStr
      }
    } else {
      coursesProgressData = JSON.parse(JSON.stringify(DefaultProgress))
      coursesProgressData.main_tt = main_ttStr
    }
  } else {
    // 一. 生成进度数据
    let mainStr = ''
    let main_ttStr = ''
    let lessonStarStr = `${lessonID}:${starNum};`

    // 判断是否是本单元最后一课
    let normalLessonArr = currentUnit.ls.filter(lesson => lesson.type === 'normal')
    if (lessonIndex === normalLessonArr.length) {
      // 1. 是本单元最后一课，则解锁下一单元第一课
      if (nextUnit) {
        // 如果下个单元是testout，则跳过testout
        if (nextUnit.ls[0].type === 'testout') {
          nextUnit = unitsData[currentUnitIndex + 2]
        }

        mainStr = `1:${nextUnit.lsIndex}:1`
        main_ttStr = `${unitID}:${lessonIndex + 1};${nextUnit.lsID}:1;`
      }
    } else {
      // 2. 不是本单元最后一课，则解锁本单元下一课
      let nextLessonIndex = lessonIndex + 1
      mainStr = `1:${unitIndex}:${nextLessonIndex}`
      main_ttStr = `${unitID}:${nextLessonIndex};`
    }
    let currentMain_ttArr =  main_ttStr.split(';').filter(item => item !== '')

    // 二. merge进度数据
    if (coursesProgressData) {
      let { main_tt, lesson_stars } = coursesProgressData

      // 1. 处理星星数据
      if (lesson_stars) {
        let lessonStarsArr = lesson_stars.split(';').filter(item => item !== '')
        let currentLessonStarStr = lessonStarsArr.find(item => item.split(':')[0] === lessonID)
  
        if (currentLessonStarStr) {
          let currentLessonStarNum = Number(currentLessonStarStr.split(':')[1])
          if (starNum > currentLessonStarNum) {
            currentLessonStarStr += ';'
            lesson_stars = lesson_stars.replace(currentLessonStarStr, lessonStarStr)
          }
        } else {
          lesson_stars += lessonStarStr
        }
      } else {
        lesson_stars = lessonStarStr
      }
      coursesProgressData.lesson_stars = lesson_stars

      // 2. 处理进度数据
      if (main_tt) {
        let main_ttArr = main_tt.split(';').filter(item => item !== '')
        for (let main_ttVal of currentMain_ttArr) {
          let oldMain_ttStr = main_ttArr.find(item => item.split(':')[0] === main_ttVal.split(':')[0])
          if (oldMain_ttStr) {
            main_tt = main_tt.replace(oldMain_ttStr, main_ttVal)
          } else {
            main_tt += main_ttVal + ';'
          }
        }
        
        coursesProgressData.main_tt = main_tt
      } else {
        coursesProgressData.main = mainStr
      }
    } else {
      coursesProgressData = JSON.parse(JSON.stringify(DefaultProgress))
      coursesProgressData.main = mainStr
      coursesProgressData.lesson_stars = lessonStarStr
    }
  }

  if (progressData) {
    if (progressData[langParam]) {
      progressData[langParam]['m_course'] = coursesProgressData
    } else {
      progressData[langParam] = { m_course: coursesProgressData }
    }
  } else {
    progressData = { [langParam]: { m_course: coursesProgressData } }
  }
  
  if (userInfo) {
    await set(ref(window.db_core, `users_private/${userInfo.UID}/learning_progress/${langParam}/m_course`), coursesProgressData)
  }
  store.commit('set_progressData', progressData)
}

export async function updateDialogueProgressData(dialogueData) {
  let langParam = `${store.getters.learnLanguage}pal` // enpal / espal / frpal...
  let userInfo = store.getters.userInfo
  let progressData = store.getters.progressData
  let dialogueProgressData = progressData?.[langParam]?.m_course

  let unitID = dialogueData.unitID
  let warmUp = 0
  let practice = 0
  let speaking = 0
  if (dialogueData.type === 'dialogueWarmUp') {
    warmUp = 1
  } else if (dialogueData.type === 'dialoguePractice') {
    practice = 1
  } else if (dialogueData.type === 'dialogueSpeaking') {
    speaking = 1
  }
  let extra_progressStr = `${unitID}:${warmUp}_${practice}_${speaking};`

  if (dialogueProgressData) {
    let { extra_progress } = dialogueProgressData
    if (extra_progress) {
      let extra_progressArr = extra_progress.split(';').filter(item => item !== '')
      let currentUnitData = extra_progressArr.find(item => item.split(':')[0] === unitID)

      if (currentUnitData) {
        // 如果进度数据中有当前单元，则判断是否用当前数据覆盖
        let extraVal = currentUnitData.split(':')[1]
        let currentWarmUp = Number(extraVal.split('_')[0])
        let currentPractice = Number(extraVal.split('_')[1])
        let currentSpeaking = Number(extraVal.split('_')[2])

        if (warmUp > currentWarmUp) {
          currentWarmUp = warmUp
        }
        if (practice > currentPractice) {
          currentPractice = practice
        }
        if (speaking > currentSpeaking) {
          currentSpeaking = speaking
        }

        let currentExtra_progressStr = `${unitID}:${currentWarmUp}_${currentPractice}_${currentSpeaking}`
        extra_progress = extra_progress.replace(currentUnitData, currentExtra_progressStr)
      } else {
        // 如果进度数据中没有当前单元，则添加当前数据
        extra_progress = extra_progress + extra_progressStr
      }
    } else {
      // 如果没有进度数据，则进度数据等于当前数据
      extra_progress = extra_progressStr
    }

    dialogueProgressData.extra_progress = extra_progress
  } else {
    dialogueProgressData = JSON.parse(JSON.stringify(DefaultProgress))
    dialogueProgressData.extra_progress = extra_progressStr
  }

  if (progressData) {
    if (progressData[langParam]) {
      progressData[langParam]['m_course'] = dialogueProgressData
    } else {
      progressData[langParam] = { m_course: dialogueProgressData }
    }
  } else {
    progressData = { [langParam]: { m_course: dialogueProgressData } }
  }
  
  if (userInfo) {
    await set(ref(window.db_core, `users_private/${userInfo.UID}/learning_progress/${langParam}/m_course`), dialogueProgressData)
  }
  store.commit('set_progressData', progressData)
}

function getTodayDate () {
  let now = new Date()
  let year = now.getFullYear()
  let month = now.getMonth() + 1
  let day = now.getDate()
  if (month < 10) month = "0" + month
  if (day < 10) day = "0" + day
  let date = '' + year + month + day

  return date
}