import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import '@/assets/styles/reset.scss'
import '@/assets/styles/index.scss'
import '@/assets/fonts/iconfont.css'
import 'animate.css'
import './plugins/element.js'

// firebase
import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCcjuELMicd2JD7sf5WsP7qSUL2ISWhSPk",
  authDomain: "auth.fluenday.com",
  projectId: "enpal-project",
  storageBucket: "enpal-project.appspot.com",
  messagingSenderId: "539686846998",
  appId: "1:539686846998:web:dd280b66ac5fe3a4692b37",
  measurementId: "G-32EB44D5WT",
  databaseURL: "https://fluenday.firebaseio.com"
}

const DB_core = Object.assign({}, firebaseConfig, {
  databaseURL: "https://enpal-userdata-core.firebaseio.com"
})

const DB_srs = Object.assign({}, firebaseConfig, {
  databaseURL: "https://enpal-userdata-srs.firebaseio.com"
})

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

const app_db_core = initializeApp(DB_core, 'app_db_core')
const app_db_srs = initializeApp(DB_srs, 'app_db_srs')
const auth_core = getAuth(app_db_core)
const auth_srs = getAuth(app_db_srs)

const db_core = getDatabase(app_db_core)
const db_srs = getDatabase(app_db_srs)

Vue.prototype.auth = auth
Vue.prototype.auth_core = auth_core
Vue.prototype.auth_srs = auth_srs
Vue.prototype.db_core = db_core
Vue.prototype.db_srs = db_srs

window.auth = auth
window.auth_core = auth_core
window.auth_srs = auth_srs
window.db_core = db_core
window.db_srs = db_srs

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
